/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.aboutSectionContainer{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    position: relative;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    width:100%;
    height:fit-content;
    top:0;
    left:0;
    background: none;
    color: white;
    padding:80px 0px;
    opacity: 1;
    margin: 0;
    margin-top: 5em;
}



.particlesContainer{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
}
.aboutContainer,.aboutTeamContainer{
    width: 100%;
    height:48%;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;

    font-family: 'Tilt Neon', cursive;
    padding:0 2em;
    gap:4.1em;
    background: none;
    
    
}

.aboutTeamContainer{
   
    margin-bottom: 12em;
}
.libraryImageContainer,.teamPicture{
    position: relative;
    background: url("../assets/libraryImage.png") 0px 0px;
    border-radius: 10px;
    height:51vh;
    width: clamp(500px,40%,600px);
    background-size: cover;
    background-position: center;
    z-index: 5;
    border: 2px solid rgb(80, 222, 230);
    -webkit-box-shadow: 0px 0px 10px 2px rgb(0, 187, 187);
            box-shadow: 0px 0px 10px 2px rgb(0, 187, 187);
    
}
.teamPicture{
    background: url("../assets/teamImage.png") 0px 0px;
   background-size: cover;
   background-position: center;
    
}
.teamPicture::before{
    content:"Coming Soon";
    color:#ADD8E6;
    position: relative;
    top:-33px;
    left:calc(100% - 115px)
}

.clubContainer,.teamInfo{
    height:61%;
  
    width: clamp(500px,35%,900px);
    max-width: 93%;
    padding: .1em 0em;
    z-index: 5;
    margin-bottom: 0em;
    
    

}
.clubTitle,.teamTitle{
    font-weight: bold;
    font-size: 2.25em;
    display: block;
    margin-bottom: .6em;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border-bottom: 5px solid rgb(0, 187, 187);
}
.clubInformation,.teamInformation{
   
    font-size: 1.7em;
    
}
.learnMore{
    font-size: .75em;
    text-decoration: underline;
    color:rgb(0, 167, 167);
    
}
.ftcContainer{
    width: 100%;
    height:300px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    background: white;
    opacity: 1;
}
.ftcLink{
    color: rgb(0, 183, 255);
    text-decoration: underline;
    margin: 0;
    padding: 10px;
 
}
.learnMoreText{
    font-size: 1.25em;
    text-align: center;
}
.ftcImage{
    margin-top: 2.25em;
    background: url("../assets/ftcImage.jpg");
    background-repeat: no-repeat;
    background-size: contain;
    width:340px;
    height: 140px;
}
@media only screen and (max-width: 1100px){
.clubInformation,.teamInformation{
    font-size:1.3em;
}
.libraryImageContainer,.teamPicture{

    height:43vh;
}
@media only screen and (max-width: 1100px){
    .clubInformation,.teamInformation{
        font-size:1.3em;
        width: 375px;
        max-width: 88vw;
    }
}
@media only screen and (max-width: 690px){
    .aboutSectionContainer{
        height: fit-content;
        padding: 0;
        margin: 0;
        gap:0;
        margin-bottom: 5em;
    }
.aboutContainer,.aboutTeamContainer{
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin:4em 0em ;
    padding:0em .3em;
    height:fit-content;
    gap:0;
    text-align: left;
    max-width: 88vw;

   
}


.libraryImageContainer,.teamPicture{
    width:375px;
    max-width: 88vw;
    background-size: cover;
    margin-top: 65px;
    height: 45vh;
    z-index: 5;
    
}
.clubContainer,.teamInfo{
   
    width: 375px;
    max-width: 88vw;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: left;
        -ms-flex-align: left;
            align-items: left;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin: 0;
    padding: 0;
    margin-top: 4em;
    padding-top: 0;
    z-index: 5;
}
.clubTitle,.teamTitle{
    padding-top:0;
}
.clubInformation,.teamInformation{
    padding: .1em .25em;
    margin: 0;
}
}

}