/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/


.resourceTitle{
 
  color: white;
  z-index: 40;
  font-size: 2em;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  left:calc(50vw - 110px);
  text-align: center;
  position: relative;
  margin-bottom: 1.2em;
  font-size: 2.75em;
  border-bottom: 4px solid rgb(0, 187, 187);
  margin-top: 3.5em;
  
}
.resourcesContainer{
  height:-webkit-fit-content;
  height:-moz-fit-content;
  height:fit-content;
  width:100%;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: left;
      -ms-flex-align: left;
          align-items: left;
  gap:40px;
  padding: 1em;
  margin-bottom: 5em;
}

.slidesContainer,.notebookContainer,.externalContainer{

max-height: -webkit-fit-content;

max-height: -moz-fit-content;
z-index: 5;
max-height: fit-content;
  min-height: 100%;
  width:30%;
  position: relative;
  border: 4px solid rgb(0, 187, 187);
  border-radius: 24px;
  -webkit-box-shadow: 0px 0px 14px 2px rgb(0, 187, 187);
          box-shadow: 0px 0px 14px 2px rgb(0, 187, 187);
  padding: 1em;
  background: rgba(0, 187, 187,.25);
  padding-bottom: 2.75em;
  padding-left: 1.25em;
 


}

.externalTitle,.notebookTitle,.slidesTitle{
  color:white;

  margin-bottom: .55em;
  position: relative;
  left:-1px;
  font-size: 1.9em;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-bottom: 3px solid rgb(0, 187, 187);
}
.notebookText,.externalText,.slidesText{
font-size: 1.35em;
  color: rgb(200, 255, 255);
  text-decoration-line: underline;
  margin-top: .9em;
  display: block;
transition: all .05s ease-in-out;
  
}

.resourceLink{
  margin-top: 20em;
}
.notebookText:hover,.externalText:hover,.slidesText:hover{
  font-size: 1.37em;
    color: rgb(160, 255, 255);
  
    
  }

@media only screen and (max-width: 690px){
 .resourcesContainer{
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 35px;
  
 }
 .slidesContainer,.notebookContainer,.externalContainer{


  width:80%;

  border: 3px solid rgb(0, 187, 187);
  -webkit-box-shadow: 0px 0px 15px 3px rgb(0, 187, 187);
          box-shadow: 0px 0px 15px 3px rgb(0, 187, 187);

}

}