.modalContainer{
    width:100vw;
    height: 100vh;
    position: fixed;
    top:0;
    left:0;
    display: flex;
    justify-content: center;
    align-items: left;
    
    background: rgba(0,0,0,.6);
    z-index: 444;
}
.close:hover{
    cursor: pointer;
    color: rgb(33, 210, 223);
}
.modal{
    margin-top: 50px;
    width:500px;
    height: 300px;
    background: white;
    min-height: fit-content;
    border-radius: 35px;
    background: linear-gradient(90deg, rgba(72,71,130,.95) 2%, rgba(0,134,161,.55) 100%);
    border: 3px solid rgb(0, 187, 187);
    box-shadow: 0px 0px 35px 4px rgb(0, 187, 187);
    max-width: 83vw;
}
.modalHeader{
    display:flex;
    align-items: center;
    justify-content: space-evenly;
    margin: .83em 0em;
    margin-bottom: 1.75em;
    font-size: 1.1em;
    color: rgb(196, 255, 255);
    border-bottom: 0px solid rgb(156, 226, 226);
}
.contactRow{
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    width: 100%;
    position: relative;
    gap:30px;
    padding-left: 30px;
    margin-bottom: .8em;
    font-size: 1.6em;
    
}
.contactRow>a{
    color: rgb(188, 255, 255);
    position: relative;
    left:-40px;
    border-bottom: 3px solid rgba(35, 173, 173, 0);
}
.contactRow>a:hover{
    color: rgb(35, 173, 173);
    position: relative;
    left:-30px;
    border-bottom: 3px solid rgb(35, 173, 173);
}
.faicon{
    width: 78px;
}

.close{
    font-size: 1.9em;
    position: relative;
    left:35px
}

@media only screen and (max-width: 650px){
    .modal{
        height: fit-content;
    }
 .contactRow{
    margin-left: 0em;
    padding-left: 5px;
 }
 .contactRow>a{
    color: rgb(188, 255, 255);
    position: relative;
    left:0px;
    margin-bottom: -.2em;
    font-size: .8em;
    
    border-bottom: 3px solid rgba(35, 173, 173, 0);
}
.contactRow{
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: relative;
    padding: 0;
    gap:5px;
    margin-bottom: 1.4em;
    
}
.faicon{
    position: relative;
    width:40px;
}
.modalHeader{
    font-size: .85em;
    position: relative;
    left:-12px;
}
.close{
    left:-0px;
}
.modal{
    max-width: 94vw;
}
}


