/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/


*{
    @import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400&display=swap');
    font-family: 'Source Sans Pro', sans-serif;
}
.navContainer{
    margin: 0;
    top:0;
    left: 0;
    background: -webkit-gradient(linear,left top, left bottom,from(rgba(0, 0, 0, 0.17)),to(rgba(0, 0, 0, 0.012)));
    background: -o-linear-gradient(rgba(0, 0, 0, 0.17),rgba(0, 0, 0, 0.012));
    background: linear-gradient(rgba(0, 0, 0, 0.17),rgba(0, 0, 0, 0.012));
    position: absolute;
    height:105px;
    width:100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-transition:all .25s ease-in-out;
    -o-transition:all .25s ease-in-out;
    transition:all .25s ease-in-out;
    z-index: 213434;
}
a:hover{
    cursor: pointer;
}

.activeBackground{
    background: -webkit-gradient(linear, left top, right top, color-stop(2%, rgba(72,71,130,1)), to(rgba(0,134,161,1)));
    background: -o-linear-gradient(left, rgba(72,71,130,1) 2%, rgba(0,134,161,1) 100%);
    background: linear-gradient(90deg, rgba(72,71,130,1) 2%, rgba(0,134,161,1) 100%);
    border-bottom: 5px solid rgb(0, 0, 65);
    height:90px;
    position: fixed;
}

img{
    margin-left: 5em;
}
img:hover{
    cursor: pointer;
   
}
ul>div{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    position: relative;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    height:100%;
    width:100%;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
   
    
}
ul{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    position: relative;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    height:100%;
    width:100%;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    margin-left: 2em;
  
 
}



li {
    list-style: none;
    
}
ul>div>li>a,li>a {
    text-decoration: none;
    color:white;
    padding:1.3rem;
    margin:1rem;
    font-size: 1.4em;
    border-bottom:2px solid rgba(0, 0, 0, 0);
    -webkit-transition: all .08s ease-in-out;
    -o-transition: all .08s ease-in-out;
    transition: all .08s ease-in-out;
}
a{
    text-decoration: none;
    transition: all .1s ease-in-out;
}
.activeText>div>li>a,.activeText>li>a{
   
    color: white;
}
.unactiveText>div>li>a:hover,.unactiveText>li>a:hover{
    color:#15f7fc;
    border-bottom: 3px solid #15f7fc;
}
ul>div>li>a:hover{
    color:#15f7fc;
    border-bottom: 4px solid #15f7fc;
}

.contactBtn{
    border-radius: 2em;
    border:2px solid #15f7fc;
    padding: .55em 1em;
    margin-right: 2em;
    -webkit-transition: .07s all ease-in-out;
    -o-transition: .07s all ease-in-out;
    transition: .07s all ease-in-out;
    
   
}
ul>li{

    position: relative;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
}

.contactBtn:hover{
    background: rgb(68, 190, 190);
}

.openBtn{
    display:none;
}

@media only screen and (max-width: 1100px){
    li>a{
        margin-top: 10px;
        font-size: 1rem;
        margin: 0em;
    }
    .contactBtn{
        margin-top: 10px;
    }
    ul{
        margin-left: 0em;
    }
    ul>div>li>a , li>a{

        font-size: 1.25em;
        padding: 0em .1em;
 
    }
}

@media only screen and (max-width: 650px){
    img{
        margin-left: .2em;
    }
    .navContainer{
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        position: fixed;
        height:85px;
       
    }
    
    .openBtn{
        display: inline;
        position: relative;
        height:80px;
        width: 80px;
        border:none;
        margin-right: 0em;
        z-index: 34343;
        background: rgba(112, 105, 105, 0);
        
    }
    
  ul{
    position: absolute;
  
    height:100vh;
    width: 60vw;
    background: -webkit-gradient(linear, left top, right top, color-stop(2%, rgba(72,71,130,.95)), to(rgba(0,134,161,1)));
    background: -o-linear-gradient(left, rgba(72,71,130,.95) 2%, rgba(0,134,161,1) 100%);
    background: linear-gradient(-95deg, rgba(72,71,130,.95) 2%, rgba(0,134,161,1) 100%);
    border-left: 2px solid rgb(116, 183, 247);
    box-shadow: 0px 0px 35px 1px rgb(0, 187, 187);
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    right:50%;
    top:0px;
    padding: 0;
    -webkit-transition: all .35s ease-in-out;
    -o-transition: all .35s ease-in-out;
    transition: all .35s ease-in-out;

  }
  ul>div{
    position: relative;
    
    width:100%;
    margin: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
   
    
  }
  div>li{
    position: relative;
    width: 100%;
    height:85px;
  }
  ul>li{
    height:200px;
  }
  ul>div>li>a:hover{
   
    border-bottom: none;
 
    
}
ul>div>li>a{
    color:white;
    border-bottom:2px solid white;
    width:100%;
  
    
}
.activeText>div>li>a,.activeText>li>a{
   
    color: rgb(255, 255, 255);
}
.contactBtn{
   border:2px solid white;
   margin-bottom: 4em;
}
.contactBtn:hover{
    background: rgb(211, 211, 211);
}
  
}