/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
*{
    font-family: 'Roboto', sans-serif;

}
.homeBackgroundLogo{
    background: url('../assets/hazardlogo.png');
    background-repeat: no-repeat;
    background-size:34%;
    background-position: center;
    background-position-x: center;
    z-index: -3434;
    height:100vh;
    width:100%;
    left:0;
    right:0;
    position: fixed;
}
.homeContainer{
    z-index: 5;
}
.clubName {
    font-size: 40px; 
    font-family: 'Roboto Light', sans-serif;
    color: #15f7fc; 
}

.backgroundImage{
    top:0;
    left:0;
    
    position: relative;
    background-position: center;
    background-size: cover;
    width: 100%;
    z-index: 5;
    height:84vh;
}
.backgroundVideo{
    z-index: 5;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
    position: relative;

}
.backgroundImgTextContainer{
    z-index: 5;
    position: relative;
    top:-104%;
    left:0;
    height:100%;
    width:100%;
    background: rgba(0,0,0,.29);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    
    
}
.imgTextContainer{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    font-size: 2.25em;
}

.imgTextContainer>p1{
    margin: .2em;
 
    font-size: 1.45em;
    color:white;
    height:-webkit-fit-content;
    height:-moz-fit-content;
    height:fit-content;
    width:-webkit-fit-content;
    width:-moz-fit-content;
    width:fit-content;
    padding: .6em;
    border:4px solid;
    border-radius: 5px;

    
    
}

.imgTextContainer>p4,.imgTextContainer>p3{
    font-size: 1em;
    color: rgba(255,255,255,.9);
    font-family: 'Itim', cursive;
}

@media only screen and (max-width: 1250px){
  .backgroundImage{
   
  
    background-size: cover;
 
  }

}

@media only screen and (max-width: 650px){
    .imgTextContainer>p{
        font-size: 1em;
    }
    .imgTextContainer>p1{
        font-size: .7em;
    }

    .homeBackgroundLogo{
        background-size: 60%;
    }
    .backgroundVideo{
       display: none;
    }
    .backgroundImgTextContainer{
top:-21%;
font-size: 1.1em;
height: 135%;
    }
.backgroundImage{
    background: url("../assets/homeBackgroundImg.jpg");
    background-size: cover;
}
}