@import url(https://fonts.googleapis.com/css2?family=Lobster&family=Tilt+Neon&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);

* {
    box-sizing: border-box;
    margin: 0;
    padding:0;
  }

.appContainer{
    overflow-x: hidden;
}
/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/


*{
    font-family: 'Source Sans Pro', sans-serif;
}
.navContainer{
    margin: 0;
    top:0;
    left: 0;
    background: linear-gradient(rgba(0, 0, 0, 0.17),rgba(0, 0, 0, 0.012));
    position: absolute;
    height:105px;
    width:100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    transition:all .25s ease-in-out;
    z-index: 213434;
}
a:hover{
    cursor: pointer;
}

.activeBackground{
    background: linear-gradient(90deg, rgba(72,71,130,1) 2%, rgba(0,134,161,1) 100%);
    border-bottom: 5px solid rgb(0, 0, 65);
    height:90px;
    position: fixed;
}

img{
    margin-left: 5em;
}
img:hover{
    cursor: pointer;
   
}
ul>div{
    display:flex;
    flex-direction: row;
    position: relative;
    align-items: center;
    height:100%;
    width:100%;
    justify-content: center;
   
    
}
ul{
    display:flex;
    flex-direction: row;
    position: relative;
    align-items: center;
    height:100%;
    width:100%;
    justify-content: space-between;
    margin-left: 2em;
  
 
}



li {
    list-style: none;
    
}
ul>div>li>a,li>a {
    text-decoration: none;
    color:white;
    padding:1.3rem;
    margin:1rem;
    font-size: 1.4em;
    border-bottom:2px solid rgba(0, 0, 0, 0);
    transition: all .08s ease-in-out;
}
a{
    text-decoration: none;
    transition: all .1s ease-in-out;
}
.activeText>div>li>a,.activeText>li>a{
   
    color: white;
}
.unactiveText>div>li>a:hover,.unactiveText>li>a:hover{
    color:#15f7fc;
    border-bottom: 3px solid #15f7fc;
}
ul>div>li>a:hover{
    color:#15f7fc;
    border-bottom: 4px solid #15f7fc;
}

.contactBtn{
    border-radius: 2em;
    border:2px solid #15f7fc;
    padding: .55em 1em;
    margin-right: 2em;
    transition: .07s all ease-in-out;
    
   
}
ul>li{

    position: relative;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.contactBtn:hover{
    background: rgb(68, 190, 190);
}

.openBtn{
    display:none;
}

@media only screen and (max-width: 1100px){
    li>a{
        margin-top: 10px;
        font-size: 1rem;
        margin: 0em;
    }
    .contactBtn{
        margin-top: 10px;
    }
    ul{
        margin-left: 0em;
    }
    ul>div>li>a , li>a{

        font-size: 1.25em;
        padding: 0em .1em;
 
    }
}

@media only screen and (max-width: 650px){
    img{
        margin-left: .2em;
    }
    .navContainer{
        align-items: center;
        position: fixed;
        height:85px;
       
    }
    
    .openBtn{
        display: inline;
        position: relative;
        height:80px;
        width: 80px;
        border:none;
        margin-right: 0em;
        z-index: 34343;
        background: rgba(112, 105, 105, 0);
        
    }
    
  ul{
    position: absolute;
  
    height:100vh;
    width: 60vw;
    background: linear-gradient(-95deg, rgba(72,71,130,.95) 2%, rgba(0,134,161,1) 100%);
    border-left: 2px solid rgb(116, 183, 247);
    box-shadow: 0px 0px 35px 1px rgb(0, 187, 187);
    justify-content: space-evenly;
    flex-direction: column;
    right:50%;
    top:0px;
    padding: 0;
    transition: all .35s ease-in-out;

  }
  ul>div{
    position: relative;
    
    width:100%;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
   
    
  }
  div>li{
    position: relative;
    width: 100%;
    height:85px;
  }
  ul>li{
    height:200px;
  }
  ul>div>li>a:hover{
   
    border-bottom: none;
 
    
}
ul>div>li>a{
    color:white;
    border-bottom:2px solid white;
    width:100%;
  
    
}
.activeText>div>li>a,.activeText>li>a{
   
    color: rgb(255, 255, 255);
}
.contactBtn{
   border:2px solid white;
   margin-bottom: 4em;
}
.contactBtn:hover{
    background: rgb(211, 211, 211);
}
  
}
.modalContainer{
    width:100vw;
    height: 100vh;
    position: fixed;
    top:0;
    left:0;
    display: flex;
    justify-content: center;
    align-items: left;
    
    background: rgba(0,0,0,.6);
    z-index: 444;
}
.close:hover{
    cursor: pointer;
    color: rgb(33, 210, 223);
}
.modal{
    margin-top: 50px;
    width:500px;
    height: 300px;
    background: white;
    min-height: -webkit-fit-content;
    min-height: -moz-fit-content;
    min-height: fit-content;
    border-radius: 35px;
    background: linear-gradient(90deg, rgba(72,71,130,.95) 2%, rgba(0,134,161,.55) 100%);
    border: 3px solid rgb(0, 187, 187);
    box-shadow: 0px 0px 35px 4px rgb(0, 187, 187);
    max-width: 83vw;
}
.modalHeader{
    display:flex;
    align-items: center;
    justify-content: space-evenly;
    margin: .83em 0em;
    margin-bottom: 1.75em;
    font-size: 1.1em;
    color: rgb(196, 255, 255);
    border-bottom: 0px solid rgb(156, 226, 226);
}
.contactRow{
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    width: 100%;
    position: relative;
    grid-gap:30px;
    gap:30px;
    padding-left: 30px;
    margin-bottom: .8em;
    font-size: 1.6em;
    
}
.contactRow>a{
    color: rgb(188, 255, 255);
    position: relative;
    left:-40px;
    border-bottom: 3px solid rgba(35, 173, 173, 0);
}
.contactRow>a:hover{
    color: rgb(35, 173, 173);
    position: relative;
    left:-30px;
    border-bottom: 3px solid rgb(35, 173, 173);
}
.faicon{
    width: 78px;
}

.close{
    font-size: 1.9em;
    position: relative;
    left:35px
}

@media only screen and (max-width: 650px){
    .modal{
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
    }
 .contactRow{
    margin-left: 0em;
    padding-left: 5px;
 }
 .contactRow>a{
    color: rgb(188, 255, 255);
    position: relative;
    left:0px;
    margin-bottom: -.2em;
    font-size: .8em;
    
    border-bottom: 3px solid rgba(35, 173, 173, 0);
}
.contactRow{
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: relative;
    padding: 0;
    grid-gap:5px;
    gap:5px;
    margin-bottom: 1.4em;
    
}
.faicon{
    position: relative;
    width:40px;
}
.modalHeader{
    font-size: .85em;
    position: relative;
    left:-12px;
}
.close{
    left:-0px;
}
.modal{
    max-width: 94vw;
}
}



/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/
*{
    font-family: 'Roboto', sans-serif;

}
.homeBackgroundLogo{
    background: url(/static/media/hazardlogo.112b07f8.png);
    background-repeat: no-repeat;
    background-size:34%;
    background-position: center;
    background-position-x: center;
    z-index: -3434;
    height:100vh;
    width:100%;
    left:0;
    right:0;
    position: fixed;
}
.homeContainer{
    z-index: 5;
}
.clubName {
    font-size: 40px; 
    font-family: 'Roboto Light', sans-serif;
    color: #15f7fc; 
}

.backgroundImage{
    top:0;
    left:0;
    
    position: relative;
    background-position: center;
    background-size: cover;
    width: 100%;
    z-index: 5;
    height:84vh;
}
.backgroundVideo{
    z-index: 5;
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative;

}
.backgroundImgTextContainer{
    z-index: 5;
    position: relative;
    top:-104%;
    left:0;
    height:100%;
    width:100%;
    background: rgba(0,0,0,.29);
    display: flex;
    align-items: center;
    justify-content: center;
    
    
}
.imgTextContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 2.25em;
}

.imgTextContainer>p1{
    margin: .2em;
 
    font-size: 1.45em;
    color:white;
    height:-webkit-fit-content;
    height:-moz-fit-content;
    height:fit-content;
    width:-webkit-fit-content;
    width:-moz-fit-content;
    width:fit-content;
    padding: .6em;
    border:4px solid;
    border-radius: 5px;

    
    
}

.imgTextContainer>p4,.imgTextContainer>p3{
    font-size: 1em;
    color: rgba(255,255,255,.9);
    font-family: 'Itim', cursive;
}

@media only screen and (max-width: 1250px){
  .backgroundImage{
   
  
    background-size: cover;
 
  }

}

@media only screen and (max-width: 650px){
    .imgTextContainer>p{
        font-size: 1em;
    }
    .imgTextContainer>p1{
        font-size: .7em;
    }

    .homeBackgroundLogo{
        background-size: 60%;
    }
    .backgroundVideo{
       display: none;
    }
    .backgroundImgTextContainer{
top:-21%;
font-size: 1.1em;
height: 135%;
    }
.backgroundImage{
    background: url(/static/media/homeBackgroundImg.4413afdb.jpg);
    background-size: cover;
}
}
/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.aboutSectionContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    flex-direction: column;
    width:100%;
    height:-webkit-fit-content;
    height:-moz-fit-content;
    height:fit-content;
    top:0;
    left:0;
    background: none;
    color: white;
    padding:80px 0px;
    opacity: 1;
    margin: 0;
    margin-top: 5em;
}



.particlesContainer{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
}
.aboutContainer,.aboutTeamContainer{
    width: 100%;
    height:48%;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    font-family: 'Tilt Neon', cursive;
    padding:0 2em;
    grid-gap:4.1em;
    gap:4.1em;
    background: none;
    
    
}

.aboutTeamContainer{
   
    margin-bottom: 12em;
}
.libraryImageContainer,.teamPicture{
    position: relative;
    background: url(/static/media/libraryImage.21c66cc2.png) 0px 0px;
    border-radius: 10px;
    height:51vh;
    width: clamp(500px,40%,600px);
    background-size: cover;
    background-position: center;
    z-index: 5;
    border: 2px solid rgb(80, 222, 230);
    box-shadow: 0px 0px 10px 2px rgb(0, 187, 187);
    
}
.teamPicture{
    background: url(/static/media/teamImage.f0467433.png) 0px 0px;
   background-size: cover;
   background-position: center;
    
}
.teamPicture::before{
    content:"Coming Soon";
    color:#ADD8E6;
    position: relative;
    top:-33px;
    left:calc(100% - 115px)
}

.clubContainer,.teamInfo{
    height:61%;
  
    width: clamp(500px,35%,900px);
    max-width: 93%;
    padding: .1em 0em;
    z-index: 5;
    margin-bottom: 0em;
    
    

}
.clubTitle,.teamTitle{
    font-weight: bold;
    font-size: 2.25em;
    display: block;
    margin-bottom: .6em;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border-bottom: 5px solid rgb(0, 187, 187);
}
.clubInformation,.teamInformation{
   
    font-size: 1.7em;
    
}
.learnMore{
    font-size: .75em;
    text-decoration: underline;
    color:rgb(0, 167, 167);
    
}
.ftcContainer{
    width: 100%;
    height:300px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: white;
    opacity: 1;
}
.ftcLink{
    color: rgb(0, 183, 255);
    text-decoration: underline;
    margin: 0;
    padding: 10px;
 
}
.learnMoreText{
    font-size: 1.25em;
    text-align: center;
}
.ftcImage{
    margin-top: 2.25em;
    background: url(/static/media/ftcImage.5112d61e.jpg);
    background-repeat: no-repeat;
    background-size: contain;
    width:340px;
    height: 140px;
}
@media only screen and (max-width: 1100px){
.clubInformation,.teamInformation{
    font-size:1.3em;
}
.libraryImageContainer,.teamPicture{

    height:43vh;
}
@media only screen and (max-width: 1100px){
    .clubInformation,.teamInformation{
        font-size:1.3em;
        width: 375px;
        max-width: 88vw;
    }
}
@media only screen and (max-width: 690px){
    .aboutSectionContainer{
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        padding: 0;
        margin: 0;
        grid-gap:0;
        gap:0;
        margin-bottom: 5em;
    }
.aboutContainer,.aboutTeamContainer{
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin:4em 0em ;
    padding:0em .3em;
    height:-webkit-fit-content;
    height:-moz-fit-content;
    height:fit-content;
    grid-gap:0;
    gap:0;
    text-align: left;
    max-width: 88vw;

   
}


.libraryImageContainer,.teamPicture{
    width:375px;
    max-width: 88vw;
    background-size: cover;
    margin-top: 65px;
    height: 45vh;
    z-index: 5;
    
}
.clubContainer,.teamInfo{
   
    width: 375px;
    max-width: 88vw;
    display: flex;
    justify-content: center;
    align-items: left;
    flex-direction: column;
    margin: 0;
    padding: 0;
    margin-top: 4em;
    padding-top: 0;
    z-index: 5;
}
.clubTitle,.teamTitle{
    padding-top:0;
}
.clubInformation,.teamInformation{
    padding: .1em .25em;
    margin: 0;
}
}

}
/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/


.resourceTitle{
 
  color: white;
  z-index: 40;
  font-size: 2em;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  left:calc(50vw - 110px);
  text-align: center;
  position: relative;
  margin-bottom: 1.2em;
  font-size: 2.75em;
  border-bottom: 4px solid rgb(0, 187, 187);
  margin-top: 3.5em;
  
}
.resourcesContainer{
  height:-webkit-fit-content;
  height:-moz-fit-content;
  height:fit-content;
  width:100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: left;
  grid-gap:40px;
  gap:40px;
  padding: 1em;
  margin-bottom: 5em;
}

.slidesContainer,.notebookContainer,.externalContainer{

max-height: -webkit-fit-content;

max-height: -moz-fit-content;
z-index: 5;
max-height: fit-content;
  min-height: 100%;
  width:30%;
  position: relative;
  border: 4px solid rgb(0, 187, 187);
  border-radius: 24px;
  box-shadow: 0px 0px 14px 2px rgb(0, 187, 187);
  padding: 1em;
  background: rgba(0, 187, 187,.25);
  padding-bottom: 2.75em;
  padding-left: 1.25em;
 


}

.externalTitle,.notebookTitle,.slidesTitle{
  color:white;

  margin-bottom: .55em;
  position: relative;
  left:-1px;
  font-size: 1.9em;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-bottom: 3px solid rgb(0, 187, 187);
}
.notebookText,.externalText,.slidesText{
font-size: 1.35em;
  color: rgb(200, 255, 255);
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  margin-top: .9em;
  display: block;
transition: all .05s ease-in-out;
  
}

.resourceLink{
  margin-top: 20em;
}
.notebookText:hover,.externalText:hover,.slidesText:hover{
  font-size: 1.37em;
    color: rgb(160, 255, 255);
  
    
  }

@media only screen and (max-width: 690px){
 .resourcesContainer{
  flex-direction: column;
  align-items: center;
  grid-gap: 35px;
  gap: 35px;
  
 }
 .slidesContainer,.notebookContainer,.externalContainer{


  width:80%;

  border: 3px solid rgb(0, 187, 187);
  box-shadow: 0px 0px 15px 3px rgb(0, 187, 187);

}

}
